.navbar {
    align-items: center;
    background-color: #0091FF;
    color: #ffffff;
    display: flex;
    height: 60px;
    justify-content: space-between;
    margin-bottom: 40px;
    padding: 0;
    width: 100%;
}

.navbar .row {
    margin: 0 auto;
    padding: 0 20px;
}

.navbar .fa-house {
    font-size: 28px;
    margin-right: 4px;
    transform: translateY(-2px);
}

.navbar a {
    color: #ffffff;
    font-family: 'Carrois Gothic SC', sans-serif;
    font-size: 32px;
    text-decoration: none;
}