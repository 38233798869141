form {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.form-left {
    flex: 2;
}

.form-right {
    align-items: flex-end;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
}

.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
}

.form-group>label {
    font-family: 'Carrois Gothic SC', sans-serif;
}

label {
    margin-bottom: 6px;
}

input[type=text], textarea {
    border: 1px solid #202020;
    border-radius: 4px;
    font-size: 16px;
    padding: 6px 8px;
    width: 600px;
}

textarea {
    height: 300px;
    resize: none;
}

input.button-link {
    border: none;
    width: 148px;
}

select {
    appearance: none;
    background-color: transparent;
    border: none;
    cursor: inherit;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    margin: 0;
    outline: none;
    padding: 0 1em 0 0;
    width: 100%;
}

select::-ms-expand {
    display: none;
}

.select {
    background-color: #fff;
    background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
    border: 1px solid #202020;
    border-radius: 0.25em;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    height: 40px;
    line-height: 34px;
    padding: 0 8px;
    position: relative;
    width: 600px;
}

.select::after {
    content: "\f107";
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    position: absolute;
    right: 12px;
    top: 3px;
}

.form-check-label {
    font-size: 16px;
    display: grid;
    grid-template-columns: 16px 104px;
    gap: 8px;
}

.form-check-label:focus-within {
    color: #0091FF;
}

input[type="radio"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    -moz-appearance: none;
    appearance: none;
    /* For iOS < 15 */
    background-color: #ffffff;
    /* Not removed via appearance */
    margin: 0;
    font: inherit;
    color: #202020;
    width: 16px;
    height: 16px;
    border: 2px solid #202020;
    border-radius: 50%;
    transform: translateY(5px);
    display: grid;
    place-content: center;
}

input[type="radio"]::before {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #0091FF;
    /* Windows High Contrast Mode */
    background-color: CanvasText;
}

input[type="radio"]:checked::before {
    transform: scale(1);
}

input[type="radio"]:focus {
    outline: max(2px, 0.15em) solid #202020;
    outline-offset: max(2px, 0.15em);
}


@media(max-width: 640px) {
    form {
        flex-direction: column;
    }

    input[type=text], textarea, .select {
        width: 100%;
    }
}