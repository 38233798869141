.table {
    border-top: 1px solid #979797;
    width: 100%;
}

.row .table .table-row,
.row .table .scene-block {
    border-bottom: 1px solid #D7D7D7;
    margin: 8px 0 0;
    padding-bottom: 8px;
}

.link+.link {
    padding-left: 16px;
}

.scene-description-block {
    margin: 16px 0 48px;
    width: 100%;
}

.row .scene-block .row {
    border-bottom: none;
}

.row .row.scene-parent,
.row .scene-block .scene-parent {
    margin-bottom: 16px;
}

.scene-block h4 {
    transform: translateY(-1px);
}

.row .row.scene-children,
.row .scene-block .scene-children {
    margin-top: 16px;
}

.scene-description {
    line-height: 1.8;
    margin: 8px 0 24px;
    max-width: 640px;
}

.create-linked-scene::before {
    background-color: #D7D7D7;
    content: '';
    height: 1px;
    margin: 24px 0 16px;
    width: 100px;
}

.tag {
    background-color: #FF7CB2;
    border-radius: 4px;
    color: white;
    display: inline-flex;
    font-size: 12px;
    height: 16px;
    line-height: 14px;
    margin: 0 0 0 8px;
    padding: 0 12px;
    text-transform: capitalize;
    transform: translateY(-2px);
}

.open-children {
    margin-left: 8px;
    margin-right: 8px;
    width: 16px;
}

.row .row.row-children {
    border-top: 1px solid #D7D7D7;
}

.row .row.row-children .tree-row {
    padding-left: 24px;
}

.scene-tree {
    width: 100%;
}

.scene-tree .row .row-parent {
    flex: 2;
    padding: 8px 0;
}

.tree-row .row-links {
    flex: 1;
}

.scene-tree>.tree-row {
    border-bottom: 1px solid #D7D7D7;
}

.scene-tree .link-caret {
    margin-top: 10px;
}

.scene-tree .tree-row,
.row .row.row-children .tree-row {
    margin: 0;
    padding-bottom: 0;
}

@media(max-width: 640px) {
    .scene-short-description {
        flex-direction: column;
    }

    .row .scene-short-description .row-links {
        justify-content: flex-start;
        margin-top: 8px;
    }

    .scene-description {
        max-width: 90%;
    }
}