@import url('https://fonts.googleapis.com/css2?family=Carrois+Gothic+SC&family=Zen+Maru+Gothic&display=swap');

* {
    box-sizing: border-box;
    font-family: 'Zen Maru Gothic', sans-serif;
}

html, body {
    margin: 0;
    padding: 0;
}

h1, h2, h3, h4 {
    display: inline-block;
    font-family: 'Carrois Gothic SC', sans-serif;
    margin: 0;
    padding: 0;
    transform: translateY(1px);
}

p {
    margin: 0;
    padding: 0;
}

a {
    display: inline-block;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

h2 {
    font-size: 24px;
}

/* LINKS */
.button-link {
    align-items: center;
    background-color: #15CC00;
    border-radius: 4px;
    color: #ffffff;
    display: inline-flex;
    font-family: 'Carrois Gothic SC', sans-serif;
    font-size: 20px;
    height: 40px;
    justify-content: center;
    padding: 0 20px;
    text-decoration: none;
    transition: background-color .3s;
    white-space: nowrap;
}

.button-link:hover {
    background-color: #10A200;
    text-decoration: none;
}

.button-link-mini {
    background-color: #ffffff;
    border: 2px solid #15CC00;
    color: #15CC00;
    height: 24px;
    font-size: 16px;
}

.button-link-mini:hover {
    background-color: #ffffff;
    border: 2px solid #10A200;
    color: #10A200;
}

.button-link-mini.button-link-secondary {
    border: 2px solid #41ADFF;
    color: #41ADFF;
}

.button-link-mini.button-link-secondary:hover {
    border: 2px solid #8899AD;
    color: #8899AD;
}

.button-link+.button-link {
    margin-left: 8px;
}

.link {
    color: #0091FF;
    font-family: 'Carrois Gothic SC', sans-serif;
    font-size: 18px;
    text-decoration: none;
    white-space: nowrap;
}

.link-text {
    font-family: 'Zen Maru Gothic', sans-serif;
    font-size: 16px;
    white-space: inherit;
}

.link-caret::after {
    content: "\f105";
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    margin-left: 4px;
}

.link-action {
    color: #10A200;
}

.link+.link,
h2+.link,
h3+.link,
h4+.link {
    padding-left: 24px;
}

/* ROWS */
.row {
    display: flex;
    margin: 0 auto 16px;
    max-width: 1024px;
    padding: 0 24px 8px;
    width: 100%;
}

.row-column {
    flex-direction: column;
}

.row .row {
    margin: 0 auto;
    padding: 0;
}

.row-vertical-bottom {
    align-items: flex-end;
}

.row-vertical-center {
    align-items: center;
}

.row-right {
    justify-content: flex-end;
}

.row-space-between {
    justify-content: space-between;
}

.self-start {
    align-self: flex-start;
}

.fake-select {
    border: 2px solid #202020;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    list-style-type: none;
    margin: 0 8px;
    padding: 0;
    position: relative;
    width: 100px;
}

.fake-select.open {
    border-radius: 4px 4px 0 0;
    border-bottom-color: transparent;
}

.fake-select .selected-clone {
    cursor: pointer;
    padding: 0 0 2px 8px;
    text-transform: capitalize;
}

.fake-select .selected-clone::after {
    content: "\f107";
    float: right;
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    margin-right: 4px;
    margin-top: 4px;
}

.fake-select li.choices {
    cursor: pointer;
    display: none;
}

.fake-select.open li.choices ul {
    border: 2px solid black;
    border-top-color: transparent;
    border-radius: 0 0 4px 4px;
    display: flex;
    flex-direction: column;
    left: -2px;
    list-style-type: none;
    margin: 0;
    padding: 2px 0 0;
    position: absolute;
    top: 20px;
    width: 100px;
    z-index: 10;
}

.fake-select li.choice {
    cursor: pointer;
}

.fake-select li.choice.selected {
    display: inline-flex;
}

.fake-select.open li.choice.selected .list-link {
    background-color: #41ADFF;
}

.fake-select.open li.choices {
    display: flex;
}

.list-link {
    background-color: #ffffff;
    color: #373737;
    padding: 0 0 2px 8px;
    width: 100%;
}

li.choice.selected .list-link {
    color: #202020;
    font-weight: 900;
}


.fake-select.open li.choice.selected .list-link {
    color: #FFFFFF;
}

@media(max-width: 640px) {
    .row {
        max-width: 620px;
    }

    .table-row .row,
    .row-header {
        flex-direction: column;
    }

    .row-header .button-link {
        font-size: 16px;
        height: 24px;
        margin-top: 12px;
    }

    .button-link {
        padding: 0 12px;
    }

    .row .row.scene-parent, .row .scene-block .scene-parent {
        align-items: flex-start;
        flex-direction: column;
    }

    .row .row.scene-parent *+.link {
        padding-left: 0;
    }
}